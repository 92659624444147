<template>
  <div>
    <div
      class="text-white dropdown pointer d-flex pt-2 location-search-component"
      :id="modalId"
      @click="openPopOver()"
    >
      <b-icon-geo-alt-fill></b-icon-geo-alt-fill>
      <span v-if="getCurrentLocation" class="mx-2 tx-15 font-weight-normal">{{
        getCurrentLocation
      }}</span>
      <span v-else class="mx-2 tx-13 font-weight-normal"
        >Select Your Location</span
      >
      <b-icon-chevron-down></b-icon-chevron-down>
    </div>

    <b-popover :target="modalId" ref="popover" placement="bottom">
      <div>
        <h6 class="tx-17 pointer">
          Selected Location :
          <b-icon-geo-alt-fill variant="orange"></b-icon-geo-alt-fill>
          {{ getCurrentLocation }}
          <b-button
            size="sm"
            variant="light"
            @click="closePopOver"
            class="close"
          >
            &times;
          </b-button>
        </h6>

        <b-row class="my-2">
          <b-col md="12">
            <GmapAutocomplete
              id="LocationAddress1"
              @place_changed="placechange1"
              :search-input.sync="search"
              ref="address"
              placeholder="Enter Your Location"
              class="form-control tx-13"
              :options="{
                fields: ['geometry', 'formatted_address', 'address_components'],
              }"
            ></GmapAutocomplete>
          </b-col>

          <b-col md="12" class="pt-0 pb-0">
            <b-button
              @click="detectMyLocation"
              variant="outline-warning"
              class="text-size tx-13 w-100"
              >Use Your Location</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <h5 v-if="locationPermission" class="text-red tx-11 margin-bottom-6">
          Please give permission to access your location
        </h5>
      </div>
    </b-popover>
    <b-modal
      id="location-popup"
      size="sm"
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      centered
      header-class=""
      body-class="p-0"
    >
      <b-card style="padding: 10px" class="popup-card">
        <div class="closemodel">
          <button type="button" @click="$bvModal.hide('location-popup')">
            <span
              ><img src="img/address/close.png" class="close-icon" alt=""
            /></span>
          </button>
        </div>
        <b-row>
          <b-icon-geo-alt-fill
            variant="orange"
            class="mr-2 location-icon"
          ></b-icon-geo-alt-fill>
          <h6 class="tx-20 pointer add-location">Add Location</h6>
        </b-row>
        <b-row>
          <div class="label">Enter Location</div>
          <GmapAutocomplete
            id="LocationAddress1"
            @place_changed="placechange1"
            ref="address"
            :search-input.sync="search"
            placeholder="Enter Your Location"
            v-model="getCurrentLocation"
            class="form-control tx-13 mt-2"
            :options="{
              fields: ['geometry', 'formatted_address', 'address_components'],
            }"
          ></GmapAutocomplete>
        </b-row>
        <b-row>
          <img
            src="https://i.ibb.co/WVv5gvq/detect.png"
            alt="detect"
            class="detect-icon mt-4 ml-2"
            border="0"
          />
          <b-card-text class="detect-location" @click="detectMyLocation">
            Detect My Location
          </b-card-text>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import location from "../mixins/location";
export default {
  mixins: [location],
  data() {
    return {
      locationPermission: false,
      position: {
        lat: 0,
        lng: 0,
      },
      search: "",
    };
  },
  computed: {
    ...mapState({
      currentPosition: (state) => state.location.currentPosition,
      defaultAddress: (state) => state.address.defaultAddress,
    }),
    getCurrentLocation() {
      return this.currentPosition?.address.substring(0, 14) + "...";
    },
  },
  watch: {
    search(val) {
      if (!val) {
        return;
      }
      this.geocodeGoogle(val);
    },
  },
  props: {
    modalId: {
      default: () => "popover-1",
      type: String,
    },
  },
  methods: {
    geocodeGoogle(val) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.placechange1(val);
      }, 1000);
    },
    async fetchMyAddress(success) {
      await axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${success.coords.latitude},${success.coords.longitude}&key=AIzaSyDHCbgMAU-KzqFmI8LtrG4DWepIm7mAJJM`
        )
        .then((result) => {
          if (result && result.data && result.data.results.length > 0) {
            this.placechange(result.data.results[0]);
          }
          localStorage.setItem(
            "lat-lng",
            JSON.stringify(result.data.results[0].geometry.location)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async detectMyLocation() {
      document.getElementById("LocationAddress1").value = "";
      await navigator.geolocation.getCurrentPosition(
        async (success) => {
          const data = JSON.parse(localStorage.getItem("lat-lng"));
          if (
            success.coords.latitude !== data.lat &&
            success.coords.longitude !== data.lng
          ) {
            await this.fetchMyAddress(success);
            setTimeout(() => {
              this.$bvModal.hide("location-popup");
            }, 1200);
            this.locationPermission = false;
          }
        },
        (err) => {
          console.log(err);
          this.$bvModal.show("location-popup");
          this.locationPermission = true;
        }
      );
      this.$refs.popover.$emit("close");
    },
    async placechange1(e) {
      let place = await e;
      let position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.locationPermission = false;
      place.geometry.location = position;
      this.placechange(place);
      //document.location.reload();
      document.getElementById("LocationAddress1").value =
        this.currentPosition.data.City;
      this.$bvModal.hide("location-popup");
      sessionStorage.setItem(
        "eparisheva:location",
        JSON.stringify(this.currentPosition)
      );
      this.$refs.popover.$emit("close");
    },
    openPopOver() {
      this.$refs.popover.$emit("open");
    },
    closePopOver() {
      this.$refs.popover.$emit("close");
    },
  },
  async mounted() {
    await this.$store.dispatch("address/fetchDeliveryAddress");
    let locationData = JSON.parse(
      sessionStorage.getItem("eparisheva:location")
    );
    const defaultAdressData = {
      address_components: [
        {
          long_name: this.defaultAddress?.city,
          types: ["locality"],
        },
      ],
      formatted_address: `${this.defaultAddress?.city}, ${this.defaultAddress?.state}, ${this.defaultAddress?.country}`,
      geometry: {
        location: {
          lat: this.defaultAddress?.location?.coordinates[0],
          lng: this.defaultAddress?.location?.coordinates[1],
        },
      },
    };
    if (Object.keys(this.defaultAddress).length !== 0) {
      this.placechange(defaultAdressData);
      locationData = {
        Lat: this.defaultAddress?.location?.coordinates[0],
        Lng: this.defaultAddress?.location?.coordinates[1],
        address: `${this.defaultAddress?.city}, ${this.defaultAddress?.state}, ${this.defaultAddress?.country}`,
        data: {
          City: this.defaultAddress?.city,
          Country: this.defaultAddress?.country,
          State: this.defaultAddress?.state,
        },
      };
    } else {
      if (navigator.geolocation) {
        if (this.$route.name === "Home") {
          this.$bvModal.show("location-popup");
        }
        await navigator.geolocation.getCurrentPosition(
          async (success) => {
            await this.fetchMyAddress(success);
            this.$bvModal.hide("location-popup");
            this.locationPermission = false;
          },
          (err) => {
            console.log(err);
            this.locationPermission = true;
          }
        );
      }
    }
    if (locationData)
      this.$store.dispatch("location/actionCurrentPosition", locationData);
  },
};
</script>
<style scoped>
.text-red {
  color: red;
}
.btn-outline-warning {
  color: #272727;
  border-color: #ff6a2e;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #fe7b08;
  border-color: #272727;
}
@media (max-width: 768px) {
  .location-mobile-view .padding-top-34 {
    padding-top: 0 !important;
  }
  .location-search-component {
    padding-top: 0 !important;
  }
  .popup-card {
    width: 100% !important;
  }
}
.popup-card {
  width: 350px;
  height: 240px;
}
.closemodel {
  position: absolute;
  top: 8px;
  right: 3px;
}
.detect-icon {
  height: 35px;
  width: 35px;
  margin-top: 15px;
}
.label {
  margin-left: 5px;
  margin-top: 20px;
  font-weight: 500;
  color: #fc7039;
}
.detect-location {
  color: #fc7039;
  font-weight: 600;
  margin-top: 28px;
  margin-left: 15px;
  cursor: pointer;
}
.text {
  padding-left: 1px;
  margin-left: 1px;
}
.text-field {
  margin-left: 12px;
}
.apply-color {
  color: #fc7039;
  font-weight: 600;
}
</style>
