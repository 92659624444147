var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-white dropdown pointer d-flex pt-2 location-search-component",
    attrs: {
      "id": _vm.modalId
    },
    on: {
      "click": function click($event) {
        return _vm.openPopOver();
      }
    }
  }, [_c('b-icon-geo-alt-fill'), _vm.getCurrentLocation ? _c('span', {
    staticClass: "mx-2 tx-15 font-weight-normal"
  }, [_vm._v(_vm._s(_vm.getCurrentLocation))]) : _c('span', {
    staticClass: "mx-2 tx-13 font-weight-normal"
  }, [_vm._v("Select Your Location")]), _c('b-icon-chevron-down')], 1), _c('b-popover', {
    ref: "popover",
    attrs: {
      "target": _vm.modalId,
      "placement": "bottom"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "tx-17 pointer"
  }, [_vm._v(" Selected Location : "), _c('b-icon-geo-alt-fill', {
    attrs: {
      "variant": "orange"
    }
  }), _vm._v(" " + _vm._s(_vm.getCurrentLocation) + " "), _c('b-button', {
    staticClass: "close",
    attrs: {
      "size": "sm",
      "variant": "light"
    },
    on: {
      "click": _vm.closePopOver
    }
  }, [_vm._v(" × ")])], 1), _c('b-row', {
    staticClass: "my-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('GmapAutocomplete', {
    ref: "address",
    staticClass: "form-control tx-13",
    attrs: {
      "id": "LocationAddress1",
      "search-input": _vm.search,
      "placeholder": "Enter Your Location",
      "options": {
        fields: ['geometry', 'formatted_address', 'address_components']
      }
    },
    on: {
      "place_changed": _vm.placechange1,
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      }
    }
  })], 1), _c('b-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "text-size tx-13 w-100",
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": _vm.detectMyLocation
    }
  }, [_vm._v("Use Your Location")])], 1)], 1)], 1), _c('div', [_vm.locationPermission ? _c('h5', {
    staticClass: "text-red tx-11 margin-bottom-6"
  }, [_vm._v(" Please give permission to access your location ")]) : _vm._e()])]), _c('b-modal', {
    attrs: {
      "id": "location-popup",
      "size": "sm",
      "hide-footer": true,
      "hide-header": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true,
      "centered": "",
      "header-class": "",
      "body-class": "p-0"
    }
  }, [_c('b-card', {
    staticClass: "popup-card",
    staticStyle: {
      "padding": "10px"
    }
  }, [_c('div', {
    staticClass: "closemodel"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('location-popup');
      }
    }
  }, [_c('span', [_c('img', {
    staticClass: "close-icon",
    attrs: {
      "src": "img/address/close.png",
      "alt": ""
    }
  })])])]), _c('b-row', [_c('b-icon-geo-alt-fill', {
    staticClass: "mr-2 location-icon",
    attrs: {
      "variant": "orange"
    }
  }), _c('h6', {
    staticClass: "tx-20 pointer add-location"
  }, [_vm._v("Add Location")])], 1), _c('b-row', [_c('div', {
    staticClass: "label"
  }, [_vm._v("Enter Location")]), _c('GmapAutocomplete', {
    ref: "address",
    staticClass: "form-control tx-13 mt-2",
    attrs: {
      "id": "LocationAddress1",
      "search-input": _vm.search,
      "placeholder": "Enter Your Location",
      "options": {
        fields: ['geometry', 'formatted_address', 'address_components']
      }
    },
    on: {
      "place_changed": _vm.placechange1,
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      }
    },
    model: {
      value: _vm.getCurrentLocation,
      callback: function callback($$v) {
        _vm.getCurrentLocation = $$v;
      },
      expression: "getCurrentLocation"
    }
  })], 1), _c('b-row', [_c('img', {
    staticClass: "detect-icon mt-4 ml-2",
    attrs: {
      "src": "https://i.ibb.co/WVv5gvq/detect.png",
      "alt": "detect",
      "border": "0"
    }
  }), _c('b-card-text', {
    staticClass: "detect-location",
    on: {
      "click": _vm.detectMyLocation
    }
  }, [_vm._v(" Detect My Location ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }