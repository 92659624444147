export default {
    methods: {
      placechange(event) {
        let locationObj = {
          address: "",
          data: {},
          Lat: "",
          Lng: "",
        };
        var componentForm = {
          street_number: "short_name",
          route: "long_name",
          locality: "long_name",
          administrative_area_level_1: "long_name",
          country: "long_name",
          postal_code: "long_name",
          sublocality_level_1: "long_name",
        };
        if (event.formatted_address) {
          locationObj.address = event.formatted_address;
        }
        if (event.geometry.location) {
          locationObj.Lat = event.geometry.location.lat;
          locationObj.Lng = event.geometry.location.lng;
        }
        let data = {};
        for (var i = 0; i < event.address_components.length; i++) {
          var addressType = event.address_components[i].types[0];
          if (componentForm[addressType]) {
            data[addressType] =
              event.address_components[i][componentForm[addressType]];
          }
        }
        if (data) {
          if (data.locality) {
            locationObj.data.City = data.locality;
          }
          if (data.country) {
            locationObj.data.Country = data.country;
          }
          if (data.administrative_area_level_1) {
            locationObj.data.State = data.administrative_area_level_1;
          }
          if (data.sublocality_level_1) {
            locationObj.data.Area = data.sublocality_level_1;
          }
          if (data.postal_code) {
            locationObj.data.zip = data.postal_code;
          }
        }
        this.$store.dispatch("location/actionCurrentPosition", locationObj);
      },
    },
  };